import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="home"
export default class extends Controller {

    static values = {
        trainingsUrl: String
    }

    async connect() {
        await this.searchRootTrainings()
    }

    async searchRootTrainings() {
        await get(this.trainingsUrlValue, {responseKind: 'turbo-stream'})
    }

}
